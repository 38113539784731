<template>
    <section>

        <AppBodyHeader />

    </section>
</template>

<script>

import AppBodyHeader from '@/components/AppBody/AppBodyHeader'

export default {
    
    name: 'About',
    
    components: {
        AppBodyHeader
    }

}
</script>